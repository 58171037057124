import { Router, Routes } from 'react-router-dom'
import '../Styles/App.css'
import HeroSection from '../Components/HeroSection'
import EmailForm from '../Components/Email/EmailForm'

function Home() {
    return (
        <>
            <HeroSection></HeroSection>
        </>
    )
}

export default Home