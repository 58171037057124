import React from 'react'
import EmailForm from '../Components/Email/EmailForm'

function Email() {
  return (
    <div>
        <EmailForm></EmailForm>
    </div>
  )
}

export default Email