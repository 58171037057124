import React from 'react'
import HobbyPhotoAlbum from './HobbyPhotoAlbum'

function HobbiesSection() {
  return (
    <div>
        <section>
            <div>
                {/* <h2>Hobbies</h2> */}
                    <p>
                        {/* This space will be updated soon... */}
                        <HobbyPhotoAlbum></HobbyPhotoAlbum>
                    </p>
                </div>
        </section>
    </div>
  )
}

export default HobbiesSection