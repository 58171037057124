import React from 'react'
import CaddySection from '../Components/CaddySection'

function Caddy() {
  return (
    <>
        <CaddySection></CaddySection>
    </>
  )
}

export default Caddy