import React from 'react'
import MusicianSection from '../Components/WorkExperience/Musician/MusicianSection'

function Musician() {
  return (
    <div>
        <MusicianSection></MusicianSection>
    </div>
  )
}

export default Musician